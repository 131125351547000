import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import CompetitionApplyTeamForm from '../components/CompetitionApplyTeamForm'

function CompetitionApplyTeampage() {
  return (
    <>
      <Navbar />
      <CompetitionApplyTeamForm />
    </>
  )
}

export default CompetitionApplyTeampage
