import React from 'react'
import Navbar from '../components/Navbar'
import PaymentSuccess from '../components/PaymentSuccess'
import Footer from '../components/Footer'

function PaymentSuccessPage() {
  return (
    <>
      <Navbar />
      <PaymentSuccess />
      <Footer />
    </>
  )
}

export default PaymentSuccessPage
