import React from 'react'
import AdminUserList from '../components/AdminUserList/AdminUserList.js'

function AdminUserListPage() {
  return (
    <>
      <AdminUserList />
    </>
  )
}

export default AdminUserListPage
