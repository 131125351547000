import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import ChooseApplyMethod from '../components/ChooseApplyMethod'

function CompetitionApplyMethodPage() {
  return (
    <>
      <Navbar />
      <ChooseApplyMethod />
      <Footer />
    </>
  )
}

export default CompetitionApplyMethodPage
